import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import imgLoader from '../../../assets/img/loader.gif';
import { getClientCertificates } from '../../../redux/actions/ConsultSignedDocumentsAction';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import IconSuccess from '../../../assets/img/iconSucces.svg';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Modal from '@material-ui/core/Modal';
import ButtonPassword from '../../../components/ButtonPassword';
import { ChangePasswordCertificate } from '../../../redux/actions/ChangePasswordCertificate';
import Loading from '../../../components/Loading';

import {
	Grid,
	Container,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
	useTheme,
	withStyles,
	Menu,
	MenuItem,
	List,
	ListItem,
	Button,
	Tooltip,
	FormControl,
	TextField,
} from '@material-ui/core';
import { helpHttp } from '../../../helpers/helpHttp';
import {
	API_HOSTNAME,
	API_HOSTNAME_AWS,
} from '../../../redux/types/ApiConstants';

const StyledTableCell = withStyles(theme => ({
	head: {
		backgroundColor: '#FFEFD8',
		font: 'normal normal bold 16px/16px Muli',
		letterSpacing: '0px',
		color: '#E55200',
		opacity: '1',
	},
	body: {
		font: 'normal normal 16px/13px Muli',
		color: '#575756',
		padding: theme.spacing(2),
		overflowY: 'auto',
	},
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: '#fff',
		},
	},
}))(TableRow);

const useStyles = makeStyles(theme => ({
	root: {
		margin: 0,
		flexShrink: 0,
		padding: theme.spacing(2),
	},

	imageLoading: {
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.37)',
		position: 'absolute',
		top: '0px',
		left: '0px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

	titleBackground: {
		margin: '0',
		background: 'rgba(0, 0, 0, 0.3)',
		height: '100px',
		paddingTop: '20px',
		width: '100%',
	},
	paperTable: {
		boxShadow: 'none',
		padding: '40px 30px 51px',
		maxWidth: '1024px',
		position: 'absolute',
		border: '1px solid #F5AE19',
		marginTop: '0px',
		borderRadius: '10px',
		margin: '0 auto',
	},
	title: {
		color: '#E55200',
		fontSize: '24px',
		fontFamily: 'Mulish',
		fontWeight: '700',
		margin: 0,
		textAlign: 'center',
	},
	message: {
		padding: 20,
		width: '60%',
		textAlign: 'center',
		font: 'normal normal normal 18px/22px Muli',
		letterSpacing: '0px',
		color: '#787878',
		//opacity: 1,
		backgroundColor: 'white',
		borderRadius: '10px',
	},
	messageContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '180px',
		width: '340px',
		textAlign: 'center',
	},
	iconRowTable: {
		color: '#E55200',
		margin: theme.spacing(1),
		padding: '2px',
		cursor: 'pointer',
		borderRadius: '50px',
		transition: '0.3s',
		'&:hover': {
			backgroundColor: '#0000000D',
		},
	},
	modalChangePassword: {
		maxWidth: '430px',
		maxHeigh: '634px',
	},
	modalPaper: {
		position: 'absolute',
		display: 'block',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		overflowY: 'hidden',
		border: '1px solid #F5AE19',
		marginTop: '0px',
		borderRadius: '8px',
		maxWidth: '430px',
		paddingTop: '30px',
		paddingRight: '40px',
		paddingLeft: '40px',
		paddingBottom: '43px',
		maxHeight: '624px',
	},
	modalTitle: {
		fontSize: '24px',
		margin: 'auto',
		textAlign: 'center',
		marginBottom: '24px',
		font: 'normal normal bold 18px/21px Muli',
		fontWeight: 'bold',
		letterSpacing: '0px',
		color: '#E55200',
		opacity: '1',
		lineHeight: '30px',
	},
	textListPassword: {
		fontSize: '14px',
		'& li': {
			padding: '0px',
		},
	},
	listPasswordRestriction: {
		color: '#575756',
		fontSize: '12px',

		'& ul': {
			listStyle: 'none',
			padding: '0',
			marginLeft: '10px',
			lineHeight: '22px',
		},
	},
	footer: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
	buttonStyle: {
		borderRadius: '10px',
		padding: '10px 24px',
		fontFamily: 'Mulish',
		fontSize: '16px',
		fontWeight: '800',
		lineHeight: '21px',
		border: 'none',
		cursor: 'pointer',
		backgroundColor: '#E55200',
		color: '#FFFFFF',
		marginTop: '24px',
	},
	modalPaper2: {
		position: 'absolute',
		display: 'block',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		overflowY: 'hidden',
		marginTop: '0px',
		borderRadius: '8px',
		width: '364px',
		padding: '32px',
		'&:focus-visible': {
			outline: 'none',
		},
	},
	contForm: {
		display: 'flex',
		flexDirection: 'colum',
		gap: '24px',
	},
	inputsCertificates: {
		fontFamily: 'Mulish',
		fontSize: '16px',
		lineHeight: '21.6px',
		width: '100%',
		border: '1.4px solid #706F6F',
		borderRadius: '8px',
		padding: '0',
		boxShadow: '0px 3px 0px #00000033',
		'& .MuiInputBase-input': {
			padding: '0 0 8px 20px', // Mueve el texto ingresado 20px a la derecha
			fontWeight: '400',
		},
		'& .MuiInputLabel-root': {
			transform: 'translate(20px, 14px)', // Mueve el placeholder cuando está normal
			color: 'rgba(0, 0, 0, 0.2)',
			fontWeight: '600',
			fontFamily: 'Mulish',
			fontSize: '16px',
			lineHeight: '21.6px',
		},
		'& .MuiInputLabel-shrink': {
			transform: 'translate(20px, 1px) scale(0.75)', // Mueve el label cuando se enfoca
			color: '#4F4F4F',
			fontSize: '14px',
		},
		'&:focus-visible': {
			outline: 'none',
			color: 'transparent',
		},
	},
	flex: {
		display: 'flex',
		justifyContent: 'center',
		gap: '20px',
	},
	continue: {
		borderRadius: '8px',
		border: '1px solid #E55200',
		padding: '8px 24px',
		fontFamily: 'Mulish',
		fontSize: '16px',
		fontWeight: '700',
		lineHeight: '21.6px',
		cursor: 'pointer',
		backgroundColor: '#FFFFFF',
		color: '#E55200',
	},

	buttonStyle2: {
		borderRadius: '8px',
		padding: '8px 24px',
		fontFamily: 'Mulish',
		fontSize: '16px',
		fontWeight: '800',
		lineHeight: '21.6px',
		border: 'none',
		cursor: 'pointer',
		backgroundColor: '#E55200',
		color: '#FFFFFF',
	},
}));

function TablePaginationActions(props) {
	const classes = useStyles();
	const theme = useTheme();
	const { count, page, rowsPerPage, onChangePage } = props;

	const handleFirstPageButtonClick = event => {
		onChangePage(event, 0);
	};

	const handleBackButtonClick = event => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = event => {
		onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = event => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<div className={classes.footer}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label='first page'
			>
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label='previous page'
			>
				{theme.direction === 'rtl' ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label='next page'
			>
				{theme.direction === 'rtl' ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label='last page'
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onChangePage: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

const ModalCertificates = props => {
	const { getClientCertificates, certificates } = props;
	const [loading, setLoading] = useState(true);
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [page, setPage] = useState(0);
	const [response, setResponse] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(4);
	const emptyRows =
		certificates !== null
			? rowsPerPage -
			  Math.min(rowsPerPage, certificates.length - page * rowsPerPage)
			: 0;
	const { ChangePassword } = props;
	const [optionsMenu, setOptionsMenu] = useState();
	const [modalPassword, setModalPassword] = useState(false);
	const [certificateData, setCertificateData] = useState(props);
	const [errorPassword, setErrorPassword] = useState(false);
	const [errorPasswordMessage, setErrorPasswordMessage] = useState(null);
	const [errorNewPasswordMessage, setErrorNewPasswordMessage] = useState(null);
	const [newPassword, setNewPassword] = useState(null);
	const [submitPassword, setSubmitPassword] = useState(false);
	const [currentPassword, setCurrentPassword] = useState(null);
	const [modalSuccess, setModalSuccess] = useState(false);
	const [loadingPassword, setLoadingPassword] = useState(false);
	const [modalCertificates, setModalCertificates] = useState(false);
	const verifiyPasswordRef = useRef();
	const confirmPasswordRef = useRef(null);
	const newPasswordRef = useRef(null);
	const currentPasswordRef = useRef(null);
	const [idCertificado, setIdCertificado] = useState('');
	const [password, setPassword] = useState('');
	const jwt = localStorage.getItem('JWT');
	const [loadingCertificate, setLoadingCertificate] = useState(false);

	const handleChangeId = event => {
		let value = event.target.value;
		value = value.replace(/[^a-zA-Z0-9]/g, '');
		setIdCertificado(value);
	};

	const handleChangePasswordCertificate = event => {
		setPassword(event.target.value);
	};

	const handleSubmit = async () => {
		setModalCertificates(false);
		setLoadingCertificate(true);

		const data = {
			numeroDocumento: idCertificado,
			clave: password,
		};
		try {
			const response = await fetch(
				`${API_HOSTNAME_AWS}signature/api/sign/associateCertificate`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
						Authorization: ` Bearer ${jwt}`,
					},
					body: JSON.stringify(data),
				}
			);

			const result = await response.json();
			if (!response.ok) {
				throw result;
			}

			enqueueSnackbar(result.mensaje, {
				variant: 'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
			const updatedCertificates = await getClientCertificates(enqueueSnackbar);
			setResponse(updatedCertificates);
		} catch (error) {
			// Si es un error con httpStatus es 400, se muestra el mensaje que envía el backend
			if (error && error.httpStatus === 400) {
				enqueueSnackbar(error.mensaje, {
					variant: 'error',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});
			} else if (
				error.message &&
				(error.message.includes('Failed to fetch') ||
					error.message.includes('CORS'))
			) {
				enqueueSnackbar('Ocurrió un error.\nPor favor intente de nuevo.', {
					variant: 'error',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});
			} else if (error.message && error.message.includes('Clave incorrecta')) {
				enqueueSnackbar('Credenciales incorrectas', {
					variant: 'error',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});
			} else if (
				error.message &&
				error.message.includes('Certificado no pertenece a usuario')
			) {
				enqueueSnackbar('El ID Certificado no le pertenece al usuario', {
					variant: 'error',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});
			} else {
				// Para cualquier otro error se muestra un mensaje genérico
				enqueueSnackbar('Certificado no existe.', {
					variant: 'error',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});
			}
		} finally {
			cancelCertificate();
			setLoadingCertificate(false);
		}
	};

	const cancelCertificate = () => {
		setIdCertificado('');
		setPassword('');
		setModalCertificates(false);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		(async () => {
			let respuesta = await getClientCertificates(enqueueSnackbar);
			setResponse(respuesta);
			setLoading(false);
		})();
	}, []);

	useEffect(() => {
		setCertificateData(props);
	}, [props]);

	useEffect(() => {
		setErrorPassword(false);
		setErrorPasswordMessage('');
		setNewPassword(null);
		setSubmitPassword(false);
		setCurrentPassword(null);
		setModalSuccess(false);
		setLoadingPassword(false);
	}, []);

	const onExit = () => {
		setModalPassword(false);
		setCertificateData({});
		setErrorPassword(false);
		setErrorPasswordMessage(null);
		setNewPassword(null);
		setSubmitPassword(false);
		setCurrentPassword(null);
		setModalSuccess(false);
	};

	const handleChangePasswordInfo = (event, rowIndex) => {
		setOptionsMenu(event);

		const dataCertificateRow = {
			id: certificates[rowIndex].id,
			oid: certificates[rowIndex].oid,
			name: certificates[rowIndex].cn,
			serial: certificates[rowIndex].serialNumber,
			description: certificates[rowIndex].descripcionestadocertificado,
		};
		setCertificateData(dataCertificateRow);
	};

	const handleCurrentPassword = e => {
		if (e.currentTarget.value.length > 0) {
			setCurrentPassword(e.currentTarget.value);
			// setErrorPasswordMessage(null)
			// setErrorPassword(false)
			let input = newPasswordRef.current;
			let selectInput = input.getElementsByTagName('input')[0];
			if (selectInput.value.length === 0) {
				setTimeout(() => removeClass(newPasswordRef), 10);
			}
		} else if (e.currentTarget.value.length == 0) {
			deleteText(newPasswordRef);
			deleteText(confirmPasswordRef);
			setNewPassword(null);
			setCurrentPassword(null);
			setTimeout(() => removeClass(newPasswordRef), 100);
			setTimeout(() => removeClass(confirmPasswordRef), 100);
			setErrorPasswordMessage(null);
		} else {
			setCurrentPassword(null);
		}
	};
	const handleFocused = e => {
		let password = e.currentTarget.value;
		if (password.length > 0) {
			let format = [
				'@',
				'!',
				'"',
				'#',
				'$',
				'%',
				'/',
				'(',
				')',
				'=',
				'?',
				'¿',
				'^',
				'*',
				'+',
				'-',
				',',
				'¨',
				'-',
				'-',
				'_',
				'.',
			];
			let num = ['1', '2,', '3', '4', '5', '6', '7', '8', '9', '0'];
			let minus = [
				'a',
				'b',
				'c',
				'd',
				'e',
				'f',
				'g',
				'h',
				'i',
				'j',
				'k',
				'l',
				'm',
				'n',
				'o',
				'p',
				'q',
				'r',
				's',
				't',
				'u',
				'v',
				'w',
				'x',
				'y',
				'z',
			];
			let mayus = [
				'A',
				'B',
				'C',
				'D',
				'E',
				'F',
				'G',
				'H',
				'I',
				'J',
				'K',
				'L',
				'M',
				'N',
				'O',
				'P',
				'Q',
				'R',
				'S',
				'T',
				'U',
				'V',
				'W',
				'X',
				'Y',
				'Z',
			];
			switch (false) {
				case password.length >= 10:
					setTimeout(() => removeClass(confirmPasswordRef), 30);
					setErrorPassword(true);
					setNewPassword(null);
					deleteText(confirmPasswordRef);
					return setErrorPasswordMessage('faltan carácteres');
				case format.some(item => password.includes(item)):
					setTimeout(() => removeClass(confirmPasswordRef), 30);
					setErrorPassword(true);
					setNewPassword(null);
					deleteText(confirmPasswordRef);
					return setErrorPasswordMessage('falta un carácter especial');
				case num.some(item => password.includes(item)):
					setTimeout(() => removeClass(confirmPasswordRef), 30);
					setErrorPassword(true);
					setNewPassword(null);
					deleteText(confirmPasswordRef);
					return setErrorPasswordMessage('falta un número');
				case minus.some(item => password.includes(item)):
					setTimeout(() => removeClass(confirmPasswordRef), 30);
					setErrorPassword(true);
					setNewPassword(null);
					deleteText(confirmPasswordRef);
					return setErrorPasswordMessage('falta una letra minúscula');
				case mayus.some(item => password.includes(item)):
					setTimeout(() => removeClass(confirmPasswordRef), 30);
					setErrorPassword(true);
					setNewPassword(null);
					deleteText(confirmPasswordRef);
					return setErrorPasswordMessage('falta una letra mayúscula');
				default:
					setErrorPassword(false);
					setNewPassword(password);
					deleteText(confirmPasswordRef);
					setErrorNewPasswordMessage(null);
					let input = confirmPasswordRef.current;
					let selectInput = input.getElementsByTagName('input')[0];
					if (selectInput.value.length === 0) {
						setTimeout(() => removeClass(confirmPasswordRef), 10);
					}
					return setErrorPasswordMessage(null);
			}
		} else {
			setNewPassword(null);
			deleteText(confirmPasswordRef);
			setTimeout(() => removeClass(confirmPasswordRef), 500);
			setErrorPassword(false);
			setErrorPasswordMessage(null);
			setErrorNewPasswordMessage(null);
		}
	};

	const similarPassword = e => {
		if (e.currentTarget.value.length > 0) {
			if (e.currentTarget.value == newPassword) {
				setSubmitPassword(true);
				setErrorNewPasswordMessage(null);
				return setErrorPassword(false);
			} else if (
				e.currentTarget.value.length > 0 &&
				e.currentTarget.value !== newPassword
			) {
				setErrorNewPasswordMessage('las contraseñas no coinciden');
				return setErrorPassword(true);
			}
		} else {
			setErrorNewPasswordMessage(null);
			setSubmitPassword(false);
		}
	};

	const handleChangePassword = async e => {
		e.preventDefault();
		try {
			const data = {
				Id: certificateData.id,
				Password: currentPassword,
				NewPassword: newPassword,
			};
			if (currentPassword === newPassword) {
				enqueueSnackbar(
					`La contraseña actual y la nueva contraseña no pueden ser iguales`,
					{
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
			} else {
				setModalPassword(false);
				setLoadingPassword(true);
				const response = await ChangePassword(data);
				setLoadingPassword(false);
				if (response.httpStatus == 400) {
					enqueueSnackbar(`Ocurrió un error: ${response.mensaje}`, {
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
					setSubmitPassword(false);
				}

				if (response.httpStatus == 200) {
					enqueueSnackbar('Cambio de contraseña exitoso.', {
						variant: 'success',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
					onExit();
					setModalSuccess(true);
				}
			}
		} catch (error) {
			return error;
		}
	};

	const deleteText = ref => {
		setErrorPassword(false);
		setSubmitPassword(false);
		let input = ref.current;
		let selectInput = input.getElementsByTagName('input')[0];
		selectInput.value = null;
		removeClass(ref);
	};

	const removeClass = ref => {
		let input = ref.current;
		let selectInput = input.getElementsByTagName('label')[0];
		selectInput.classList.remove('MuiInputLabel-shrink');
	};
	document.body.className = 'bodyMiddle';

	return (
		<>
			{loadingCertificate && <Loading />}
			{loadingPassword ? <Loading /> : <div></div>}
			<Grid container spacing={0} direction='row'>
				<Container component='main' maxWidth='lg'>
					<h1 className={'title-background'}>Mis Certificados</h1>
				</Container>
				<Container component='main' maxWidth='lg'>
					<Grid
						container
						spacing={0}
						direction='row'
						justify='flex-start'
						alignItems='flex-start'
						className='zone-upload-container'
					>
						{loading ? (
							<Loading />
						) : response == 0 ? (
							certificates !== null && certificates.length !== 0 ? (
								<Paper>
									<TableContainer
										component={Paper}
										className={classes.paperTable}
										style={{}}
									>
										<Table id='headerTableCertificate'>
											<TableHead className={'headerTableDocumentSigned'}>
												<TableRow>
													<StyledTableCell width='30%' align='center'>
														Nombre
													</StyledTableCell>
													<StyledTableCell width='20%' align='center'>
														Tipo de certificado
													</StyledTableCell>
													<StyledTableCell width='26%' align='center'>
														Vigencia
													</StyledTableCell>
													<StyledTableCell width='15%' align='center'>
														Identificador
													</StyledTableCell>
													<StyledTableCell width='8%' align='center'>
														Estado
													</StyledTableCell>
													{/* <StyledTableCell width='1%' align="center"></StyledTableCell> */}
												</TableRow>
											</TableHead>
											<TableBody>
												{(rowsPerPage > 0
													? certificates.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
													  )
													: certificates
												).map((row, index) => {
													return (
														<StyledTableRow key={index}>
															<StyledTableCell
																style={{ fontWeight: 'bold', lineHeight: 2 }}
																align='center'
															>
																{row.cn}
															</StyledTableCell>
															<StyledTableCell align='center'>
																{row.oid}
															</StyledTableCell>
															<StyledTableCell align='center'>
																{row.vigencia}
															</StyledTableCell>
															<StyledTableCell align='center'>
																{row.serialNumber}
															</StyledTableCell>
															<StyledTableCell align='center'>
																<Tooltip
																	title={row.descripcionestadocertificado}
																>
																	<Typography>{row.estadoCodigo}</Typography>
																</Tooltip>
															</StyledTableCell>
															{/* {
																			(row.estadoCodigo == 'EMC' || row.estadoCodigo == 'FIN') ?
																				(
																					<div className={classes.iconRowTable} id={row.oid}
																						onClick={(e) => handleChangePasswordInfo(e.currentTarget, index)}>
																						<MoreVertIcon style={{ fontSize: '2rem' }} />
																					</div>
																				)
																				: ""
																		} */}
														</StyledTableRow>
													);
												})}

												{certificates.length > 4 && emptyRows > 0 && (
													<TableRow style={{ height: 46 * emptyRows }}>
														<TableCell colSpan={5} />
													</TableRow>
												)}
											</TableBody>
										</Table>
										<div
											style={{
												display: 'flex',
												justifyContent: 'flex-end',
												width: '100%',
											}}
										>
											<button
												className={classes.buttonStyle}
												onClick={() => setModalCertificates(true)}
											>
												Asociar certificado
											</button>
										</div>
										{certificates.length > 4 && (
											<div>
												<TablePagination
													rowsPerPageOptions={[5, 10, 25]}
													component='div'
													// colSpan={3}
													count={certificates.length}
													rowsPerPage={rowsPerPage}
													page={page}
													labelRowsPerPage='Filas por página'
													onChangePage={handleChangePage}
													onChangeRowsPerPage={handleChangeRowsPerPage}
													// ActionsComponent={TablePaginationActions}
												/>
											</div>
										)}
									</TableContainer>
								</Paper>
							) : (
								<Grid
									container
									justify='center'
									className={classes.messageContainer}
								>
									<Grid item xs={12} className={classes.message}>
										<p>No tienes certificados añadidos.</p>
									</Grid>
								</Grid>
							)
						) : (
							<Grid
								container
								justify='center'
								className={classes.messageContainer}
							>
								<Grid container xs={12} className={classes.message}>
									<p>Hubo un problema al conectarse con el servidor.</p>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Container>
			</Grid>
			{/* 
			<Menu
				open={Boolean(optionsMenu)}
				anchorEl={optionsMenu}
				onClose={() => setOptionsMenu(null)}
				anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				getContentAnchorEl={null}
				PaperProps={{ style: { boxShadow: '0px 4px 4px #D3D3D3', marginLeft: '-11px' } }}

			>
				<MenuItem dense={true} onClick={(e) => {
					setOptionsMenu(null)
					setModalPassword(true)
				}}>
					<Typography>Cambiar contraseña</Typography>
				</MenuItem>
			</Menu> */}

			<Modal
				open={Boolean(modalPassword)}
				disableBackdropClick
				onClose={onExit}
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<Paper elevation={3} className={classes.modalPaper}>
					{loadingPassword ? (
						<img src={imgLoader} width='200' style={{ width: '100%' }} />
					) : (
						<>
							<Typography className={classes.modalTitle}>
								Cambiar contraseña del certificado
							</Typography>
							<div style={{ marginBottom: '20px' }}>
								<List
									className={classes.textListPassword}
									style={{ padding: '0px' }}
								>
									<ListItem style={{ fontWeight: 'bold' }}>
										{certificateData.name}
									</ListItem>
									<ListItem>Certificado {certificateData.oid}</ListItem>
									<ListItem>Serial: {certificateData.serial}</ListItem>
								</List>
							</div>

							<form ref={verifiyPasswordRef}>
								<div
									style={{
										borderBottom: '1px solid rgba(109, 110, 113, 0.19)',
										paddingBottom: '12px',
									}}
								>
									<ButtonPassword
										label={'Contraseña actual'}
										onChange={e => handleCurrentPassword(e)}
										ref={currentPasswordRef}
									/>
									<ButtonPassword
										label={'Nueva contraseña'}
										error={newPassword == null ? errorPassword : false}
										onChange={e => handleFocused(e)}
										helperText={errorPasswordMessage}
										disabled={currentPassword !== null ? false : true}
										ref={newPasswordRef}
									/>
									<ButtonPassword
										label={'Confirmar contraseña'}
										error={
											submitPassword == false && newPassword == false
												? true
												: false
										}
										disabled={newPassword == null ? true : false}
										onChange={e => similarPassword(e)}
										helperText={
											newPassword == null ? '' : errorNewPasswordMessage
										}
										ref={confirmPasswordRef}
									/>
								</div>

								<List
									className={classes.listPasswordRestriction}
									style={{ padding: '12px 0px 20px 0px' }}
								>
									<ListItem style={{ paddingLeft: 0 }}>
										La contraseña debe cumplir las siguientes condiciones:
									</ListItem>
									<ListItem style={{ paddingLeft: 10 }}>
										<ul>
											<li>•&nbsp;&nbsp;Mínimo 10 caracteres.</li>
											<li>•&nbsp;&nbsp;Una letra mayúscula.</li>
											<li>•&nbsp;&nbsp;Una letra minúscula.</li>
											<li>•&nbsp;&nbsp;Un número.</li>
											<li>
												•&nbsp;&nbsp;Un carácter especial como
												@!"#$%/()=?¿^*+-,,¨-_.
											</li>
										</ul>
									</ListItem>
								</List>
								<div style={{ display: 'flex', justifyContent: 'center' }}>
									<Button
										onClick={onExit}
										style={{
											width: '100%',
											maxWidth: '165px',
											maxHeight: '40px',
											marginRight: '10px',
											marginTop: '0px',
										}}
										className={'btnChange'}
									>
										Cancelar
									</Button>
									<Button
										type='submit'
										style={{
											width: '100%',
											maxWidth: '165px',
											maxHeight: '40px',
											marginLeft: '10px',
											marginTop: '0px',
										}}
										className={'btnSubmitPassword'}
										disabled={
											errorPassword !== true &&
											submitPassword == true &&
											currentPassword !== null
												? false
												: true
										}
										onClick={e => handleChangePassword(e)}
									>
										Aceptar
									</Button>
								</div>
							</form>
						</>
					)}
				</Paper>
			</Modal>
			<Modal
				open={Boolean(modalSuccess)}
				disableBackdropClick
				onClose={onExit}
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<Paper
					elevation={3}
					className={classes.modalPaper}
					style={{ maxHeight: '297px', width: '100%' }}
				>
					<div style={{ width: '147px', height: '147px', margin: 'auto' }}>
						<img src={IconSuccess} width='50' style={{ width: '100%' }} />
					</div>

					<Typography
						className={classes.modalTitle}
						style={{ fontSize: '18px' }}
					>
						¡Contraseña actualizada!
					</Typography>
					<Button
						style={{
							width: '130px',
							height: '41px',
							margin: 'auto',
							display: 'flex',
						}}
						className={'btnGeneral18'}
						onClick={() => {
							setModalSuccess(false);
						}}
					>
						Aceptar
					</Button>
				</Paper>
			</Modal>
			<Modal
				open={modalCertificates}
				disableBackdropClick
				onClose={onExit}
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<Paper elevation={3} className={classes.modalPaper2}>
					<div className={classes.modal}>
						<FormControl className={classes.contForm}>
							<h4 className={classes.title}>Asociar certificado</h4>
							<TextField
								id='input-with-icon-grid'
								label='ID certificado'
								className={classes.inputsCertificates}
								value={idCertificado}
								onChange={handleChangeId}
							/>
							<TextField
								id='input-with-icon-grid'
								label='Contraseña'
								type='password'
								className={classes.inputsCertificates}
								value={password}
								onChange={handleChangePasswordCertificate}
							/>

							<div className={classes.flex}>
								<button
									className={classes.continue}
									onClick={() => cancelCertificate()}
								>
									Cancelar
								</button>
								<button className={classes.buttonStyle2} onClick={handleSubmit}>
									Asociar
								</button>
							</div>
						</FormControl>
					</div>
				</Paper>
			</Modal>
		</>
	);
};

const mapStateToProps = state => ({
	certificates: state.consultSignedDocument.certificates,
});

const mapDispatchToProps = {
	getClientCertificates: getClientCertificates,
	ChangePassword: ChangePasswordCertificate,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCertificates);
